export default [
  {
    header: 'Apps',
  },
  {
    title: 'Pgs',
    icon: 'UserIcon',
    route: 'pg-index',
  },
  {
    title: 'Supervisores',
    icon: 'UserIcon',
    route: 'supervisores-index',
  },
  {
    title: 'Visitantes',
    icon: 'UserIcon',
    route: 'visitantes-index',
  },
  {
    title: 'Lições',
    icon: 'BookOpenIcon',
    route: 'lessons-index',
  },
]
